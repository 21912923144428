import React from "react";

const thankYouGseCustomer = () => {
  return (
    <div>
      <main class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-12 lg:mt-16">
        <div class="text-center">
          <img
            class="w-3/4 mx-auto"
            src="https://imgix.cosmicjs.com/1fac8f20-f7d5-11ec-8f84-3f01a4a22fe2-GSE-partnership-lockup-long.svg"
            alt="logo"
          />
          <div className="mx-auto flex w-6/12 mb-4"></div>
          <h2 class="text-2xl md:text-4xl tracking-tight md:leading-12 font-extrabold text-orange-500 sm:text-5xl sm:leading-none md:text-6xl">
            Your Content Submission{" "}
            <span class="block">Was Received Successfully!</span>
          </h2>
          <p class="max-w-4xl mx-auto mt-3 text-base text-gray-600 sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
            Thank you for the information! As we get started on your website you
            can email us anytime with any concerns or issues you may have.{" "}
            <a
              href="/consult-gse-partners/gse-customer-request"
              class="mt-3 block font-bold hover:underline"
            >
              To view your company drive please go back to the previous login
              screen for access.
            </a>
          </p>{" "}
          <div class="inline-flex justify-center">
            <div class="rounded-md">
              <div class="mt-14 rounded-md sm:flex-shrink-0">
                <a
                  href="mailto:Gse@consultfgc.com"
                  class="w-full px-20 py-5 text-lg font-medium rounded-md text-white bg-gradient-to-l from-secondaryBlue to-primaryBlue hover:opacity-70"
                >
                  Email CFGC If You Made a Mistake
                </a>
              </div>

              <div class="mt-14 rounded-md sm:flex-shrink-0">
                <a
                  href="/consult-gse-partners/gse-customer-portal"
                  class="w-full px-20 py-5 text-lg font-medium rounded-md text-white bg-gradient-to-l from-purple-900 to-purple-800 hover:opacity-70"
                >
                  Return To Customer login
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default thankYouGseCustomer;
